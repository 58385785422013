










































































































































































































































































import { Component } from 'vue-property-decorator';
import workflowModule from '@/store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
  }
})
export default class WorkflowItem extends mixins(DataLoading) {

  showWorkflowMenu = false

  toggleWorkflowMenu() {
	  this.showWorkflowMenu = !this.showWorkflowMenu
  }

  closeWorkflowSidebar() {
	  this.$emit("closeComponentEvent", "")
  }

  get workflowTasqs(): any[] {
	  var workflows: any[] = []
	  for (var x = 0; x < tasqsListModule.tasqList.length; x++) {
		  if (tasqsListModule.tasqList[x].predictionType.toLowerCase() == "workflow") {
			  if (tasqsListModule.tasqList[x].workflowDetailsID == this.workflow.id) {
			  	workflows.push(tasqsListModule.tasqList[x])
			  }

		  }
	  }
	  return workflows
  }

  goToTasqPage(tasq) {
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: tasq?.id || '',
      },
    });
    tasqsListModule.setActiveTasq(tasq?.id || '');
  }

  async created() {
	  await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
	  this.stopDataLoading();
  }


  get workflow() {
    return workflowModule.activeWorkflow as any;
  }



  get heading() {
    if (!this.workflow.result) return '';
    let finalText = '';
    this.workflow.conditions.forEach((condition, ci) => {
      if ((condition.condition || {}).value === 'STATE_OF_WELL') {
        const wellStateText = condition.recurring
          ? `current well state is <b>${(condition.conditionValue || {}).text}</b> and has been ${(condition.conditionValue || {}).text}
               <b>${(condition.recurring_data || {}).times} time(s)</b> out of
               <b>${(condition.recurring_data || {}).duration} ${(condition.recurring_data || {}).durationValue}(s)</b>`
          : `current well state is <b>${(condition.conditionValue || {}).text}</b>`;
        // eslint-disable-next-line
        finalText += `${ci <= 0 ? ' if' : (condition.connector ? ' and ' : ' or ')} ${wellStateText}`;
      } else {
        // eslint-disable-next-line
        finalText += `  <span>${ci <= 0 ? '' : `&nbsp;${condition.connector ? 'and' : 'or'}`}</span>
          <span>&nbsp;if&nbsp;</span><span style="color: rgba(1,236,220,255)" class="underline">${condition.conditionValue.text || ''}
          ${(condition.operationalModifier.text === 'Value' || !condition.operationalModifier.value) ? '' : condition.operationalModifier.text}
          ${condition.valueModifier.text === 'Greater than' || condition.valueModifier.text === 'Less than' ? 'is' : ''}
          ${condition.valueModifier.text} ${(condition.valueModifier.value === 'DECREASES_AT'
          || condition.valueModifier.value === 'INCREASES_AT') ? `${condition.value}%` : (condition.value || '')} for <b>${condition.howLong} hours</b>
          </span>
        `;
      }
    });
    finalText = `<span>A tasq will be assigned to <span style="color: rgba(1,236,220,255)" class="underline">${this.workflow.result.to.text}</span></span>
      ${finalText.toLowerCase()} for ${!this.workflow.result.source.length ? 'all wells' : this.workflow.result.source}.`;
    return finalText;
  }

  confirmDeleteOpen() {
    this.$dialog.confirm('Delete workflow?', 'This action cannot be undone.', 'info')
      .then(async (result) => {
        if (result.isOk) {
         this.startDataLoading();
         await workflowModule.deleteWorkflow(this.workflow)
         this.close();
         this.stopDataLoading();
        }
      })
  }

  editWorkflow() {
    this.$emit('change-component', 'update-workflow')
  }

  close() {
    this.$emit('close-left-component');
  }
}
